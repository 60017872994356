import React from "react"
import SEO from "../components/seo"
import GradientArea from "../components/gradientarea"
import gradientMap from "../components/gradientmap"

import "../styles/global.css"

export default class Home extends React.Component {

  componentDidMount() {
    new GradientArea()
    var audioPlayer = require('web-audio-player')
    var createAnalyser = require('web-audio-analyser')
    var createAudioContext = require('ios-safe-audio-context')
    var detectAutoplay = require('detect-audio-autoplay')
    var detectMediaSource = require('detect-media-element-source')
    var average = require('analyser-frequency-average')
    var tapEvent = require('tap-event')
    var onTap, onMouseDown

    document.addEventListener('mousemove', function(e) {
        //let body = document.querySelector('body');
        let circle = document.getElementById('circle');
        let left = e.offsetX;
        let top = e.offsetY;
        circle.style.left = left + 'px';
        circle.style.top = top + 'px';
      });
      
    onMouseDown = function (ev) {
      window.removeEventListener('touchstart', onTap)
      window.removeEventListener('mousedown', onMouseDown)
      ev.preventDefault()
      //loading.style.display = 'block'
      //clickToPlay.style.display = 'none'
      canplay()
    }
    onTap = tapEvent(onMouseDown)

    window.addEventListener('touchstart', onTap)
    window.addEventListener('mousedown', onMouseDown)
    function start (audioContext, shouldBuffer) {
      // List of sources, usually good to provide
      // a back up in case MP3 isn't supported.
      var sources = [
        '/audio/1ETERNAL.mp3'
      ]
    
      // Create a looping audio player with our audio context.
      // On mobile, we use the "buffer" mode to support AudioAnalyser.
      var player = audioPlayer(sources, {
        context: audioContext,
        buffer: true,
        loop: true
      })
    
      // Set up our AnalyserNode utility
      // Make sure to use the same AudioContext as our player!
      var audioUtil = createAnalyser(player.node, player.context, {
        stereo: false
      })
    
      // The actual AnalyserNode
      //var analyser = audioUtil.analyser
    
      // This is triggered on mobile, when decodeAudioData begins.
      player.once('decoding', function (amount) {
        //loading.innerText = 'Decoding...'
        console.log("decoding...")
      })
    
      // Only gets called when loop: false
      player.on('end', function () {
        console.log('Audio ended')
      })
    
      // If there was an error loading the audio
      player.on('error', function (err) {
        console.error(err.message)
        //loading.innerText = 'Error loading audio.'
      })
    
      // This is called with 'canplay' on desktop, and after
      // decodeAudioData on mobile.
      player.on('load', function () {
        //loading.style.display = 'none'
    
        console.log('Source:', player.element ? 'MediaElement' : 'Buffer')
        console.log('Playing', Math.round(player.duration) + 's of audio...')
    
        // start audio node
        player.play()
    
        // start the render loop
       // app.on('tick', render)
        //app.start()
        window.requestAnimationFrame(animateBg)
      })
    
      // Play/pause on tap
      var click = function () {
        if (player.playing) player.pause()
        else player.play()
        if (player.playing) {
          //clickToPlay.style.display = 'none'
        } else {
          //clickToPlay.textContent = 'Paused'
         // clickToPlay.style.display = 'block'
        }
      }
      window.addEventListener('click', click)
      function animateBg() {
        // grab our byte frequency data for this frame
        var freqs = audioUtil.frequencies()

        document.body.setAttribute("style", "background-color: rgb(" + freqs[0] + "," + freqs[4] + "," + freqs[8] + ")")
        document.getElementById("circle").style.backgroundColor ="rgb(" + freqs[0] + "," + freqs[4] + "," + freqs[8] + ")"
        

        //console.log(avg)

        //console.log(audio.node.context.currentTime)
        document.getElementById("nowplaying").innerHTML = "NOW PLAYING: "+ gradientMap.songs[0].name +" - " + Math.round(100 * player.node.context.currentTime / player.duration) + "%"
        if(player.node.context.currentTime >= player.duration) {
          //audio.stop()
          //audio = createPlayer('/audio/'+gradientMap.songs[++currentSong].filename, {context: audioContext, buffer: true})
        }
        requestAnimationFrame(animateBg)
    }
    }
    function canplay () {
      // Create an iOS-safe AudioContext which fixes
      // potential sampleRate bugs with playback
      // (The hack needs to be called on touchend for iOS!)
      var audioContext = createAudioContext()

      // Detect whether createMediaElementSource() works
      // as expected. You can also use userAgent sniffing here.
      detectMediaSource(function (supportsMediaElement) {
        // No media element support -> we should buffer
        var shouldBuffer = !supportsMediaElement
        start(audioContext, shouldBuffer)
      }, audioContext)
    }
  }

  render() {
    return (
      <div id="app">
          <SEO />
          <div id="loader" style={{background: "grey"}}></div>
          <div id="circle"></div>
          <div id="stairs"></div>
          <div id="contentarea">
            <div id="border">
              <h2 id="nowplaying" style={{color: "red"}}>
                NOW PLAYING eternal  0:00 / 0:10 
              </h2>
              <h1 id="title">
                <span id="title_g" style={{animationDelay: "0.2s"}}>g</span>
                <span id="title_r" style={{animationDelay: "0.4s"}}>r</span>
                <span id="title_a" style={{animationDelay: "0.8s"}}>a</span>
                <span id="title_d" style={{animationDelay: "1.2s"}}>d</span>
                <span id="title_i" style={{animationDelay: "1.6s"}}>i</span>
                <span id="title_e" style={{animationDelay: "2.0s"}}>e</span>
                <span id="title_n" style={{animationDelay: "2.4s"}}>n</span>
                <span id="title_t" style={{animationDelay: "2.8s"}}>t</span>
                <span id="title_space"> </span>
                <span id="title_m" style={{animationDelay: "3.2s"}}>m</span>
                <span id="title_a" style={{animationDelay: "3.6s"}}>a</span>
                <span id="title_p" style={{animationDelay: "4s"}}>p</span>
              </h1>
            </div>
          </div>
      </div>
    )
  }
}