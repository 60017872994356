import { GlowParticle } from "./glowparticle.js";

/*const COLORS = [
    {r: 45, g: 74, b: 227},
    {r: 250, g: 255, b: 89},
    {r: 255, g: 104, b: 248},
    {r: 44, g: 209, b: 252},
    {r: 54, g: 233, b: 84}
];*/

const COLORS = [
    {r: 255, g: 40, b: 100},
    {r: 18, g: 12, b: 255},
    {r: 255, g: 218, b: 12},
    {r: 12, g: 255, b: 121}
]

export default class GradientArea {
    constructor() {
        this.canvas = document.createElement("canvas");
        this.canvas.id = "gradientarea"

        document.getElementById("app").appendChild(this.canvas);
        this.ctx = this.canvas.getContext('2d');

        this.pixelRatio = (window.devicePixelRatio > 1) ? 2 : 1;

        this.totalParticles = 10;
        this.particles = [];
        this.maxRadius = 900;
        this.minRadius = 400;

        this.mousePosition = {x: 0, y: 0}

        window.addEventListener('resize', this.resize.bind(this), false);
        this.resize();
        document.getElementById("contentarea").addEventListener("mousemove", (evt)=>{
            this.setMousePos(evt)
        })
        window.requestAnimationFrame(this.animate.bind(this));
        document.getElementById("loader").style.opacity = 0;
    }

    setMousePos(evt) {
        var rect = this.canvas.getBoundingClientRect();

        this.mousePosition = 
            {x: (evt.clientX - rect.left) / (rect.right - rect.left) * this.canvas.width, 
            y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * this.canvas.height
        }
    }

    resize() {
        this.stageWidth = document.body.clientWidth;
        this.stageHeight = document.body.clientHeight;

        this.canvas.width = this.stageWidth * this.pixelRatio;
        this.canvas.height = this.stageHeight * this.pixelRatio;
        this.ctx.scale(this.pixelRatio, this.pixelRatio);
        //this.ctx.globalCompositeOperation = "saturation"
        this.createParticles();
    }

    createParticles() {
        let curColor = 0;
        this.particles = [];

        for (let i = 0; i < this.totalParticles; i++) {
            const item = new GlowParticle(
                Math.random() * this.stageWidth,
                Math.random() * this.stageHeight,
                Math.random() * 
                (this.maxRadius - this.minRadius) + this.minRadius, 
                COLORS[curColor]
            );

            if (++curColor >= COLORS.length) {
                curColor = 0;
            }
    
            this.particles[i] = item;
        }
    }

    animate() {
        window.requestAnimationFrame(this.animate.bind(this));
        //console.log(this.mousePosition);
        this.ctx.clearRect(0,0,this.stageWidth, this.stageHeight);

        for (let i = 0; i < this.totalParticles; i++) {
            const item = this.particles[i];
            item.animate(this.ctx, this.stageWidth, this.stageHeight, this.mousePosition);

        }
    }
}

