module.exports = {
    songs: [
        {
            name: "ETERNAL",
            filename: "1ETERNAL.mp3",
            bpm: 95,
            length: 100
        }, 
        {
            name: "TWISTER",
            filename: "bruh2.mp3",
            bpm: 95,
            length: 100
        }, 
        {
            name: "PERMANENT CHOKER",
            filename: "bruh1.mp3",
            bpm: 95,
            length: 100
        }, 
        {
            name: "NOW",
            filename: "bruh1.mp3",
            bpm: 95,
            length: 100
        }, 
        {
            name: "TO THE NIGHT",
            filename: "bruh1.mp3",
            bpm: 95,
            length: 100
        }, 
        {
            name: "HURRY BACK",
            filename: "eternal_01",
            bpm: 95,
            length: 100
        }, 
        {
            name: "GAUSSIAN",
            filename: "eternal_01",
            bpm: 95,
            length: 100
        }
    ]
}